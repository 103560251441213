export const BUTTON = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  BORDERLESS: 'BORDERLESS'
}

export const LINK = {
  MENU: 'MENU',
  FOOTER: 'FOOTER',
  TEXT: 'TEXT',
}

export const ASSET_TYPE = {
  USER_AVATAR: 'USER_AVATAR',
  GRIPE_IMG: 'GRIPE_IMG',
  GRIPE_VIDEO: 'GRIPE_VIDEO',
  GRIPE_AUDIO: 'GRIPE_AUDIO'
}

export const DETAILED_AVATAR_TYPE = {
  CARD: 'CARD',
  COMMENT: 'COMMENT',
  DETAIL: 'DETAIL',
  PROFILE: 'PROFILE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  PROFILE_EDIT: 'PROFILE_EDIT'
}

export const AUTH_TYPE = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  LOCAL: 'LOCAL'
}

export const GRIPE_VIEW = {
  LIST_VIEW: 'LIST_VIEW',
  PROFILE_VIEW: 'PROFILE_VIEW',
  CARD_VIEW: 'CARD_VIEW',
  FEATURED_VIEW: 'FEATURED_VIEW',
  DETAIL_VIEW: 'DETAIL_VIEW'
}

export const MESSAGE_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

export const ERROR_GROUPS = {
  NAME_ERROR: 'name',
  USERNAME_ERROR: 'username',
  EMAIL_ERROR: 'email',
  PASSWORD_ERROR: 'password',
  LOCATION_ERROR: 'location',
  TEXT_ERROR: 'text',
  TITLE_ERROR: 'title',
  CATEGORY_ERROR: 'categoryId',
  HASHTAG_ERROR: 'tags',
  ASSET_ERROR: 'assetsId',
  AVATAR_ERROR: 'avatar'
}

export const REACTION_TYPES = {
  ENRAGED_FACE: "ENRAGED_FACE",
  ANGER: "ANGER",
  TRIDENT: "TRIDENT"
}

export const REPORT_UNIT = {
  GRIPE: "GRIPE",
  COMMENT: "COMMENT"
}