import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers';
import { useRoutes } from 'react-router-dom';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Provider } from 'mobx-react'

import { persist } from 'mst-persist'
import localForage from 'localforage'
import Store from './context/root/Store' 

import { ReactNotifications} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

import { toJS } from 'mobx';

const store = Store.create({})

const App = () => {

  const content = useRoutes(routes);
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    persist(`griper-cms-${process.env.REACT_APP_API_URL}-v1.1`, store, {
      storage: localForage,
      jsonify: false,
    }).then(res => {
      setLoading(false)
      store.set('hydrated', true)
      if (store.authStore.token) {
        store.usersStore.getUser(store.accountStore.user.username)
        if (store.accountStore.user.role === 'ADMIN') {
        store.categoriesFactory.fetchCategories()
        } 
        store.gripesFactory.fetchGripes(store.authStore.token)
      }
    })

  }, [])

  return (
    <Provider store={store}>
      { store.hydrated === true &&
            <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ReactNotifications />
              <CssBaseline />
              {content}
            </LocalizationProvider>
          </ThemeProvider>
      }
    </Provider>
  );
}
export default App;
