import axios from 'axios'
import { API, BASIC_AUTH } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_comments = async (token, gripeId) => {
  try {
    const res = await axios.get(`${API}/cms/comments/all/${gripeId}`, {
      headers: {
        'Authorization': token ? token : BASIC_AUTH
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_comment = async (data, token, commentId) => {
  try {
    const res = await axios.put(`${API}/cms/comments/${commentId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}