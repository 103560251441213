import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from "../utils/error-service"

export const api_delete_asset = async (data) => {
  try {
    const res = await axios.delete(`${API}/assets/${data.id}`, {
      headers: {
        'Authorization': data.token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_asset = async (data) => {
  try {
    const res = await axios.update(`${API}/assets/${data.id}`, {
      headers: {
        'Authorization': data.token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_asset = async (data) => {
  try {
    const res = await axios.get(`${API}/assets/${data.id}`, {
      headers: {
        'Authorization': data.token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}