import { types } from "mobx-state-tree"

const Category = types
  .model("Category", {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    icon: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    update(c) {
      self.set('title', c.title)
      self.set('icon', c.icon)
    },
    set(key, value) {
      self[key] = value
    }
  }))


export default Category