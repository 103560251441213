import { getRoot, types } from "mobx-state-tree"
import User from '../models/User'
import { Store as Notify } from 'react-notifications-component';
import { api_flag_user, api_unflag_user , api_get_users_paginate, api_get_user_by_id, api_delet_user } from '../../queries/users'

const UsersStore = types
  .model('UsersStore', {
    users: types.maybeNull(types.optional(
      types.array(types.reference(User)), []
    )),
    allUsers: types.maybeNull(types.number),
    selectedUser: types.maybeNull(types.reference(User)),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
  })
  .views(self => ({
    get token() {
      return getRoot(self).authStore.token
    },
    get gripesFactory() {
      return getRoot(self).gripesFactory
    },
    get usersFactory() {
      return getRoot(self).usersFactory
    },
    get authStore() {
      return getRoot(self).authStore
    },
  }))
  .actions(self => ({
    async getUsersPaginate(page, row) {
      try {
        self.set('loading', true)
        const res = await api_get_users_paginate(self.token, page, row)

        if (res.error) throw res

        if (res.count !== 0) {
          self.set('allUsers', res.count)
        }
        const ids = await self.usersFactory.addUpdateUsers(res.rows)
        self.set('users', [])
        self.set('users', ids)
        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async getUser(username) {
      try {
        self.set('loading', true)
        const res = await api_get_user_by_id(username, self.token)

        if (res.error) throw res

        const gripeId = await self.usersFactory.addUpdateUsers([res.data])

        self.set('selectedUser', gripeId[0])

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async flagUser(username) {
      try {
        self.set('loading', true)
        const res = await api_flag_user(username, self.token)

        if (res.error) throw res

        const gripeId = await self.usersFactory.addUpdateUsers([res.data])

        self.set('selectedUser', gripeId[0])

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async unflagUser(username) {
      try {
        self.set('loading', true)
        const res = await api_unflag_user(username, self.token)

        if (res.error) throw res

        const gripeId = await self.usersFactory.addUpdateUsers([res.data])

        self.set('selectedUser', gripeId[0])

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async deletUser(id) {
      try {
        self.set('loading', true)

        const res = await api_delet_user(id, self.token)

        if (res.error) throw res

        Notify.addNotification({
          title: "User deleted!",
          message: "You have successfully deleted the user",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        
        self.set('loading', false)
        return res.data

      } catch (err) {
        Notify.addNotification({
          title: "User delete failed!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
      }
    },

    async deletUserPaginate(id, page, limit) {
      try {
        self.set('loading', true)

        const res = await api_delet_user(id, self.token)


        if (res.error) throw res
        self.getUsersPaginate(page, limit)

        Notify.addNotification({
          title: "User deleted!",
          message: "You have successfully deleted the user",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        return res.data

      } catch (err) {
        Notify.addNotification({
          title: "User delete failed!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    handleErrors(err) {
      console.log(err)
      self.authStore.checkIfStillAuthorized(err)
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default UsersStore