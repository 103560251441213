import { getRoot, types, destroy } from "mobx-state-tree"
import Gripe from '../models/Gripe'
import differenceBy from 'lodash/differenceBy'
import intersectionBy from 'lodash/intersectionBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import { api_get_gripes} from '../../queries/gripes'

const GripesFactory = types
  .model('GripesFactory', {
    gripes: types.optional(
      types.array(Gripe), []
    )
  })
  .views(self => ({
    get categoriesFactory() {
      return getRoot(self).categoriesFactory
    },
    get usersFactory() {
      return getRoot(self).usersFactory
    },
    get accountStore() {
      return getRoot(self).accountStore
    },
    get featuredGripes() {
      return self.gripes.filter(gr => gr.featured)
    },
    get featuredGripe() {
      return self.featuredGripes[Math.floor(Math.random() * self.featuredGripes?.length)]
    },
  }))
  .actions(self => ({
    addUpdateGripes(grps) {
      const gripes = self.parseGripes(grps)
      const add = differenceBy(gripes, self.gripes, 'id')
      const update = intersectionBy(self.gripes, gripes, 'id')

      map(update, g => g.update(
        filter(gripes, gr => gr.id === g.id)[0]
      ))
      self.gripes.push(...add)
      return map(gripes, g => g.id)
    },
    addUpdateGripe(gripe) {
      const ids = self.addUpdateGripes([gripe])
      return ids[0]
    },
    getGripe(id) {
      return find(self.gripes, g => g.id === id)
    },
    deleteGripe(id) {
      let gripe = find(self.gripes, g => g.id === id)
      destroy(gripe)
      return self.gripes
    },
    parseGripes(grps) {
      return map(grps, g => ({
          ...g,
          category: g.category?.id ? self.categoriesFactory.addUpdateCategory(g.category) : null,
          user: g.user?.id ? self.usersFactory.addUpdateUser(g.user) : null,
      }))
    },
    async fetchGripes(token) {
      try {
        const res = await api_get_gripes(token)
        if (res.error) throw res
        
        self.addUpdateGripes(res.data)

        return res.data
      } catch (err) {
        return err
      }
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default GripesFactory