
import { types, getRoot } from 'mobx-state-tree'
import Category from '../models/Category'
import Asset from '../models/Asset'
import { api_get_categories_paginate, api_post_category, api_delet_categories, api_get_category_by_id, api_update_category } from '../../queries/categories'
import { Store as Notify } from 'react-notifications-component';

const CategoriesStore = types
  .model('categoriesStore', {
    categories: types.maybeNull(types.optional(
      types.array(types.reference(Category)), []
    )),
    allCategories: types.maybeNull(types.number),
    selectedCategory: types.maybeNull(types.reference(Category)),

    asset: types.maybeNull(types.optional(Asset, {})),
    loadingAsset: false,
    updating: false,
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
  })
  .views(self => ({
    get authenticated() {
      return false
    },
    get categoriesFactory() {
      return getRoot(self).categoriesFactory
    },
    get authStore() {
      return getRoot(self).authStore
    },
    get token() {
      return getRoot(self).authStore.token
    },
    get categoryUploadIconUrl() {
      return self.selectedCategory.icon
    }
  }))
  .actions(self => ({
    async getCategoriesPaginate(page, row) {
      try {
        self.set('loading', true)
        const res = await api_get_categories_paginate(self.token, page, row)

        if (res.error) throw res

        if (res.count !== 0) {
          self.set('allCategories', res.count)
        }
        const ids = await self.categoriesFactory.addUpdateCategories(res.rows)
        self.set('categories', [])
        self.set('categories', ids)
        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async getCategory(id) {
      try {
        self.set('loading', true)
        const res = await api_get_category_by_id(id, self.token)

        if (res.error) throw res

        const categoryId = await self.categoriesFactory.addUpdateCategories([res.data])
        self.set('selectedCategory', categoryId[0])

        const asset = Asset.create()
        self.set('asset', asset)

        self.asset.set('localUrl', self.selectedCategory.icon)
        self.set('loading', false)

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async uploadAsset(file, preview) {
      self.set('loadingAsset', true)
      try {
        const asset = Asset.create()
        self.set('asset', asset)

        const res = await asset.upload(
          self.token,
          file,
          preview
        )

        if (res.error) throw res

        self.set('loadingAsset', false)
        return res.data
      } catch (err) {
        self.set('loadingAsset', false)
        self.handleErrors(err)
      }
    },
    async createCategory(title, row) {
      try {
        self.set('loading', true)

        const data = {
          title: title,
          iconId: self.asset.id,
        }

        const res = await api_post_category(data, self.token)

        if (res.error) throw res

        Notify.addNotification({
          title: "Category created!",
          message: "You have successfully created the category " + title, 
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        const ids = await self.categoriesFactory.addUpdateCategories([res.data])

        self.getCategoriesPaginate(self.currentPage, self.currentRow)

        self.set('loading', false)
        return res.data

      } catch (err) {

        Notify.addNotification({
          title: "Could not create category!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async updateCategory(id, title) {
      try {
        self.set('loading', true)
        
          const data = {
            title: title,
          }

        // const data = {
        //   title: title,
        //   iconId: self.asset.id,
        // }
        
        const res = await api_update_category(data, id, self.token)

        console.log(res)

        if (res.error) throw res

        self.getCategory(id)

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async updateCategoryWithAsset(id, title) {
      try {
        self.set('loading', true)

        const data = {
          title: title,
          iconId: self.asset.id,
        }
        
        const res = await api_update_category(data, id, self.token)

        console.log(res)

        if (res.error) throw res

        self.getCategory(id)

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async deletCategory(id) {
      try {
        self.set('loading', true)

        const res = await api_delet_categories(id, self.token)

        if (res.error) throw res

        Notify.addNotification({
          title: "Category deleted!",
          message: "You have successfully deleted the category",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        return res.data

      } catch (err) {
        Notify.addNotification({
          title: "Delete failed!",
          message: "You can't delete the category because it is used by some gripes.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async deletCategoryPaginate(id, page, row) {
      try {
        self.set('loading', true)

        const res = await api_delet_categories(id, self.token)

        if (res.error) throw res

        self.getCategoriesPaginate(page, row)

        Notify.addNotification({
          title: "Category deleted!",
          message: "You have successfully deleted the category",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        if (res.error) throw res

        self.set('loading', false)
        return res.data

      } catch (err) {
        Notify.addNotification({
          title: "Delete failed!",
          message: "You can't delete the category because it is used by some gripes.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    handleErrors(err) {
      console.log(err)
      self.authStore.checkIfStillAuthorized(err)
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default CategoriesStore
