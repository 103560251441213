import { getRoot, types } from "mobx-state-tree"
import User from '../models/User'
import { Store as Notify } from 'react-notifications-component';
import { api_get_team_users_paginate, api_get_user_by_id, api_delet_user } from '../../queries/users'
import { api_invite_new_team_user, api_update_team_user } from '../../queries/team'

const TeamStore = types
  .model('TeamStore', {
    teamUsers: types.maybeNull(types.optional(
      types.array(types.reference(User)), []
    )),
    allTeamUsers: types.maybeNull(types.number),
    selectedTeamUser: types.maybeNull(types.reference(User)),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
  })
  .views(self => ({
    get token() {
      return getRoot(self).authStore.token
    },
    get gripesFactory() {
      return getRoot(self).gripesFactory
    },
    get usersFactory() {
      return getRoot(self).usersFactory
    },
    get authStore() {
      return getRoot(self).authStore
    },
  }))
  .actions(self => ({
    async getTeamUsersPaginate(page, row) {
      try {
        self.set('loading', true)
        const res = await api_get_team_users_paginate(self.token, page, row)

        if (res.error) throw res

        if (res.count !== 0) {
          self.set('allTeamUsers', res.count)
        }
        const ids = await self.usersFactory.addUpdateUsers(res.rows)
        self.set('teamUsers', [])
        self.set('teamUsers', ids)
        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async getTeamUser(username) {
      try {
        self.set('loading', true)
        const res = await api_get_user_by_id(username, self.token)

        if (res.error) throw res

        const gripeId = await self.usersFactory.addUpdateUsers([res.data])

        self.set('selectedTeamUser', gripeId[0])

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async inviteNewTeamMate(email, role) {
      try {
        self.set('loading', true)

        const data = {
          email: email,
          role: role,
        }

        const res = await api_invite_new_team_user(data, self.token)

        if (res.error) throw res

        Notify.addNotification({
          title: "User invited!",
          message: "You have successfully invited a new user to the CMS ", 
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        const ids = await self.usersFactory.addUpdateUsers(res.rows)

        self.getTeamUsersPaginate(self.currentPage, self.currentRow)

        self.set('loading', false)
        return res.data

      } catch (err) {

        Notify.addNotification({
          title: "Could not invite user!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        self.handleErrors(err)
      }
    },

    async deletUser(id) {
      try {
        self.set('loading', true)

        const res = await api_delet_user(id, self.token)

        if (res.error) throw res

        Notify.addNotification({
          title: "User deleted!",
          message: "You have successfully deleted the user",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        
        self.set('loading', false)
        return res.data

      } catch (err) {
        Notify.addNotification({
          title: "User delete failed!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
      }
    },

    async deletUserPaginate(id, page, limit) {
      try {
        self.set('loading', true)

        const res = await api_delet_user(id, self.token)


        if (res.error) throw res
        self.getUsersPaginate(page, limit)

        Notify.addNotification({
          title: "User deleted!",
          message: "You have successfully deleted the user",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        return res.data

      } catch (err) {
        Notify.addNotification({
          title: "User delete failed!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
      }
    },

    async updateTeamUser(role, username) {
      try {
        self.set('loading', true)
       
        const data = {
          role: role,
        }
        
        const res = await api_update_team_user(data, self.token, username)
        if (res.error) throw res

        self.selectedTeamUser.set('role', role)

        self.getTeamUsersPaginate(self.currentPage, self.currentRow)

        //self.gripesFactory.addUpdateGripe(res.data)
        //self.clearValues()

        Notify.addNotification({
          title: "Role updated!",
          message: "You have successfully updated the role of the team member.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)

        Notify.addNotification({
          title: "Role update failed!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.handleErrors(err)
        return err
      }
    },

    async removeTeamUser(username) {

      try {
        self.set('loading', true)
       
        const data = {
          role: "NORMAL",
        }
        
        const res = await api_update_team_user(data, self.token, username)
        if (res.error) throw res

        self.getTeamUsersPaginate(self.currentPage, self.currentRow)

        Notify.addNotification({
          title: "Team member removed!",
          message: "You have successfully removed the team member.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)

        Notify.addNotification({
          title: "Delete failed!",
          message: "There was an error.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.handleErrors(err)
        
        return err
      }
    },
    handleErrors(err) {
      console.log(err)
      self.authStore.checkIfStillAuthorized(err)
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default TeamStore