import { types } from "mobx-state-tree"

const Hashtag = types
  .model("Hashtag", {
    id: types.maybeNull(types.number),
    tag: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    set(key, value) {
      self[key] = value
    }
  }))


export default Hashtag