import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_users = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/users`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_users_paginate = async (token, page, row) => {
  try {
    const res = await axios.get(`${API}/cms/users?page=${page}&items=${row}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_team_users_paginate = async (token, page, row) => {
  try {
    const res = await axios.get(`${API}/cms/users/all/cms?page=${page}&items=${row}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}


export const api_get_user_by_id = async (username, token) => {
  try {
    const res = await axios.get(`${API}/cms/users/${username}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}


export const api_flag_user = async (username, token) => {
  try {
    const res = await axios.post(`${API}/cms/users/${username}/flag`, {}, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_unflag_user = async (username, token) => {
  try {
    const res = await axios.post(`${API}/cms/users/${username}/unflag`, {}, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delet_user = async (id, token) => {
  try {
    const res = await axios.delete(`${API}/cms/users/${id}/archive`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_user = async (data, token, userId) => {
  try {
    const res = await axios.put(`${API}/cms/users/${userId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

