import { getRoot, types} from "mobx-state-tree"
import Gripe from '../models/Gripe'
import { api_get_gripes_paginate, api_get_gripe_by_id, api_get_gripes_paginate_reported } from '../../queries/gripes'
import { api_get_comments } from '../../queries/comments'

const GripeStore = types
  .model('GripeStore', {
    gripe: types.maybeNull(types.reference(Gripe)),
    gripes: types.maybeNull(types.optional(
      types.array(types.reference(Gripe)), []
    )),
    allGripes: types.maybeNull(types.number),
    allComments: types.maybeNull(types.number),
    selectedGripe: types.maybeNull(types.reference(Gripe)),
    selectedCommentId: types.maybeNull(types.number),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
  })
  .views(self => ({
    get token() {
      return getRoot(self).authStore.token
    },
    get gripesFactory() {
      return getRoot(self).gripesFactory
    },
    get isSelectedGripeFeatured() {
      return self.selectedGripe.featured
    },
    get isSelectedGripeTrending() {
      return self.selectedGripe.trending
    },
    get authStore() {
      return getRoot(self).authStore
    },
  }))
  .actions(self => ({
    async getGripesPaginate(page, row) {
      try {
        self.set('loading', true)
        const res = await api_get_gripes_paginate(self.token, page, row)

        if (res.error) throw res

        if (res.count !== 0) {
          self.set('allGripes', res.count)
        }
        const ids = await self.gripesFactory.addUpdateGripes(res.rows)
        self.set('gripes', [])
        self.set('gripes', ids)
        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async getCommentsPaginate(gripeId, pageComment, rowComment,) {
      try {
        self.set('loading', true)
        const res = await api_get_comments(self.token, gripeId, pageComment, rowComment)

        if (res.error) throw res
        if (res.count !== 0) {
          self.set('allComments', res.data.count)
        }
        self.selectedGripe.set('comments', [])
        const ids = await self.selectedGripe.addUpdateComments(res.data.rows)
        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        console.log(err)
        self.handleErrors(err)
      }
    },
    async getGripesPaginateReported(page, row) {
      try {
        self.set('loading', true)
        const res = await api_get_gripes_paginate_reported(self.token, page, row)

        if (res.error) throw res

        if (res.count !== 0) {
          self.set('allGripes', res.count)
        }
        const ids = await self.gripesFactory.addUpdateGripes(res.rows)
        self.set('gripes', [])
        self.set('gripes', ids)
        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    async getGripe(id) {
      try {
        self.set('loading', true)
        const res = await api_get_gripe_by_id(id, self.token)

        if (res.error) throw res

        const gripeId = await self.gripesFactory.addUpdateGripes([res.data])

        self.set('selectedGripe', gripeId[0])

        self.set('loading', false)
        return res.data

      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
      }
    },
    handleErrors(err) {
      console.log(err)
      self.authStore.checkIfStillAuthorized(err)
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default GripeStore