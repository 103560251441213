import { types } from "mobx-state-tree"

const Location = types
  .model("Location", {
    id: types.maybeNull(types.number),
    lat: types.maybeNull(types.number),
    lng: types.maybeNull(types.number),
    exactAddress: types.maybeNull(types.string)
  })
  .views(self => ({
    get valid() {
      return self.lat && self.lon && self.title
    }
  }))
  .actions(self => ({
    update(location) {
      self.set('lat', location.lat)
      self.set('lng', location.lng)
      self.set('exactAddress', location.exactAddress)
    },
    set(key, value) {
      self[key] = value
    }
  }))


export default Location