import { types } from "mobx-state-tree"
import Asset from './Asset'
import Location from "./Location"

const User = types
  .model('User', {
    id: types.identifierNumber,
    email: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    invitation: types.maybeNull(types.string),
    location: types.maybeNull(types.optional(
      Location, {}
    )),
    status: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    noOfGripes: types.maybeNull(types.number),
    avatar: types.maybeNull(
      types.optional(Asset, {})
    ),

    //user settings
    anonymous: types.maybeNull(types.boolean),
    emailNotifications: types.maybeNull(types.boolean),
  })
  .views(self => ({
  
  }))
  .actions(self => ({
    update(user) {
      self.set('email', user.email)
      self.set('name', user.name)
      self.set('username', user.username)
      self.set('location', user.location)
      self.set('invitation', user.invitation)
      self.set('avatar', user.avatar)
      if (user.role !== undefined) {
        self.set('role', user.role)
      }
      self.set('status', user.status)
      if (user.anonymous !== undefined) {
        self.set('anonymous', user.anonymous)
      }
      if (user.emailNotifications !== undefined) {
        self.set('emailNotifications', user.emailNotifications)
      }
      if (user.noOfGripes)
        self.set('noOfGripes', user.noOfGripes)
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default User