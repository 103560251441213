import axios from 'axios'
import { API, BASIC_AUTH } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_categories = async () => {
  try {
    const res = await axios.get(`${API}/categories`, {
      headers: {
        'Authorization': BASIC_AUTH
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_category_by_id = async (id, token) => {
  try {
    const res = await axios.get(`${API}/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delet_categories = async (id, token) => {
  try {
    const res = await axios.delete(`${API}/cms/categories/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_post_category = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/categories`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_category = async (data, id, token) => {

  try {
    const res = await axios.put(`${API}/cms/categories/${id}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_categories_paginate = async (token, page, row) => {
  try {
    const res = await axios.get(`${API}/categories?page=${page}&items=${row}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}