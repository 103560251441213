import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';

import SuspenseLoader from './components/SuspenseLoader';

import ProtectedRoute from './routes/ProtectedRoute';
import OnlyForAdmin from './routes/OnlyForAdmin';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);


// Dashboards

const Login = Loader(lazy(() => import('./content/pages/Login')));
const SignUp = Loader(lazy(() => import('./content/pages/SignUp')));
const CheckAuth = Loader(lazy(() => import('./content/pages/CheckAuth')));

// Applications

const Users = Loader(lazy(() => import('./content/applications/allUsers')));
const Gripes = Loader(lazy(() => import('./content/applications/Gripes')));
const Categories = Loader(lazy(() => import('./content/applications/Categories')));
const SingleCategory = Loader(lazy(() => import('./content/applications/SingleCategory')));
const SingleGripe = Loader(lazy(() => import('./content/applications/SingleGripe')));
const SingleUser = Loader(lazy(() => import('./content/applications/SingleUser')));
const Team = Loader(lazy(() => import('./content/applications/Team')));

// Status

const Status404 = Loader(lazy(() => import('./content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('./content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('./content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('./content/pages/Status/Maintenance')));
const NoAccess= Loader(lazy(() => import('./content/pages/Status/NoAccess')));


const routes = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'sign-up',
        element: <SignUp />
      },
      {
        path: 'verification',
        element: <CheckAuth />
      },
      {
        path: 'off-limits',
        element: <NoAccess />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ] 
  },
  {
    path: 'dashboard',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/dashboard/gripes"
            replace
          />
        )
      },
      {
        path: 'users',
        element: (
          <OnlyForAdmin>
            <Users />
          </OnlyForAdmin>
        ),
      },
      {
        path: 'gripes',
        element: <Gripes />
      },
      {
        path: 'categories',
        element: (
          <OnlyForAdmin>
            <Categories />
          </OnlyForAdmin>
        ),
      },
      {
        path: 'team',
        element: (
          <OnlyForAdmin>
            <Team />
          </OnlyForAdmin>
        ),
      },
      {
        path: 'categories/:id',
        element: (
          <OnlyForAdmin>
            <SingleCategory />
          </OnlyForAdmin>
        ),
      },
      {
        path: 'gripes/:id',
        element: <SingleGripe />
      },
      {
        path: 'users/:id',
        element: (
          <OnlyForAdmin>
            <SingleUser />
          </OnlyForAdmin>
        ),
      },
      {
        path: 'users',
        element: (
          <OnlyForAdmin>
            <Users /> 
          </OnlyForAdmin>
        ),
      },
    ]
  },

  
];

export default routes;
