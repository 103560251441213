import { types } from "mobx-state-tree"
import User from "../models/User"
import differenceBy from 'lodash/differenceBy'
import intersectionBy from 'lodash/intersectionBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import { api_get_users } from '../../queries/users'

const UsersFactory = types
  .model('UsersFactory', {
    users: types.optional(
      types.array(User), []
    )
  })
  .views(self => ({

  }))
  .actions(self => ({
    addUpdateUsers(u) {
      const users = self.parseUsers(u)
      const add = differenceBy(users, self.users, 'id')
      const update = intersectionBy(self.users, users, 'id')
      map(update, u => u.update(
        filter(users, usr => usr.id === u.id)[0]
      ))
      self.users.push(...add)
      
      return map(users, a => a.id)
    },
    addUpdateUser(user, string) {
      const ids = self.addUpdateUsers([user])
      return ids[0]
    },
    getUser(id) {
      return find(self.users, u => u.id === id)
    },
    parseUsers(usrs) {
      return map(usrs, u => ({
          ...u,
          anonymous: u.settings?.anonymous,
          emailNotifications: u.settings?.emailNotifications
      }))
    },
    async fetchUsers(token) {
      try {
        const res = await api_get_users(token)
        if (res.error) throw res
        
        self.addUpdateUsers(res.data)

        return res.data
      } catch (err) {
        return err
      }
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default UsersFactory