import { inject, observer } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = (props) => {

  const { authStore } = props.store
  
  if (!authStore.isAuthenticated) return <Navigate to="/login" replace={true} />

  return props.children
}

export default inject('store')(observer(ProtectedRoute))


