import { inject, observer } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'

const OnlyForAdmin = (props) => {

  const { accountStore } = props.store
  
  if (accountStore.user.role !== 'ADMIN') return <Navigate to="/off-limits" replace={true} />

  return props.children
}

export default inject('store')(observer(OnlyForAdmin))

