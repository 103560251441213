import { types } from "mobx-state-tree"

const Reaction = types
  .model("Reaction", {
    type: types.identifier,
    counter: types.maybeNull(types.number),
    liked: types.maybeNull(types.boolean)
  })
  .views(self => ({
    toggle() {
      self.set('liked', !self.liked)
    },
  }))
  .actions(self => ({
    update(reaction) {
      self.set('type', reaction.type)
      self.set('counter', reaction.counter)
    },
    set(key, value) {
      self[key] = value
    }
  }))


export default Reaction