import { types } from "mobx-state-tree"
import Category from '../models/Category'
import differenceBy from 'lodash/differenceBy'
import intersectionBy from 'lodash/intersectionBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import { api_get_categories } from '../../queries/categories'

const CategoriesFactory = types
  .model('CategoriesFactory', {
    categories: types.optional(
      types.array(Category), []
    )
  })
  .views(self => ({
    get updateGui() {
      return self.categories?.length
    }
  }))
  .actions(self => ({
    addUpdateCategories(categories) {
      const add = differenceBy(categories, self.categories, 'id')
      const update = intersectionBy(self.categories, categories, 'id')
     
      map(update, c => c.update(
        filter(categories, cat => cat.id === c.id)[0]
      ))

      self.categories.push(...add)
      return map(categories, a => a.id)
    },
    addUpdateCategory(category) {
      const ids = self.addUpdateCategories([category])
      return ids[0]
    },
    getCategory(id) {
      return find(self.categories, c => c.id === id)
    },
    async fetchCategories() {
      try {
        const res = await api_get_categories()
        if (res.error) throw res

        self.addUpdateCategories(res.data.rows)

        return res.data
      } catch (err) {
        return err
      }
    },
  }))

export default CategoriesFactory