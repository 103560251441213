import { getRoot, types } from 'mobx-state-tree'
import { _throw } from "../../utils/error-service";
import { api_login, api_check_invite_token, api_register } from "../../queries/auth"
import { Store as Notify } from 'react-notifications-component';

const AuthStore = types
  .model('AuthStore', {
    email: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
    inviteToken: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    inviteUserLoginType: types.maybeNull(types.string),
    inviteAuthorized: false,
    error: false,
    loading: false,
  })
  .views(self => ({
    get accountStore() {
      return getRoot(self).accountStore
    },
    get usersFactory() {
      return getRoot(self).usersFactory
    },
    get isAuthenticated() {
      return self.token
    },
    get isSSO() {
      if (self.inviteUserLoginType !== 'SSO')  {
        return true
      } else {
        return false
      }
    },
    get isRegisteredUser() {
      if (self.inviteUserLoginType === 'LOCAL')  {
        return true
      } else {
        return false
      }
    },
    get inviteEmail() {
      if (self.email !== null)  {
        return self.email
      } else {
        return ''
      }
    },
    get inviteName() {
      if (self.name !== null)  {
        return self.name
      } else {
        return ''
      }
    }
  }))
  .actions(self => ({
    async login() {
      self.set("error", false)
      self.set("loading", true)
      try {
        const res = await api_login({
          email: self.email,
          password: self.password,
        })
        
        if (res.error) throw (res)
        
        self.set("token", res.data.token)
        const userId = self.usersFactory.addUpdateUser(res.data.user)
        self.accountStore.set('user', userId)
        self.set("loading", false)
        return res.data
        } catch (err) {
          console.log(err)
          self.set("error", true)
          self.set("loading", false)
          return _throw(err)
        }
    },
    async checkAuth(inviteToken) {
      self.set("error", false)
      self.set("loading", true)
      self.set("name", '')

      try {
        const res = await api_check_invite_token(inviteToken)
        
        if (res.error) throw (res)

        self.set("inviteToken", inviteToken)
        self.set("email", res.data.email)
        if (res.data.name === null) {
          self.set("name", "")
        } else {
          self.set("name", res.data.name)
        }
        self.set("inviteUserLoginType", res.data.status)
        self.set("inviteAuthorized", true)

        self.set("loading", false)
        
        return res.data
        } catch (err) {
          console.log(err)
          self.set("error", true)
          self.set("loading", false)
          return _throw(err)
        }
    },
    async register(password, name, email) {
      self.set("error", false)
      self.set("loading", true)

      try {
        const data = {
          password: password,
          name: name,
        }

        const res = await api_register(data, self.inviteToken)
        if (res.error) throw (res)

        self.set("token", res.data.token)
        const userId = self.usersFactory.addUpdateUser(res.data.user)
        self.accountStore.set('user', userId)

        self.set("loading", false)
        
        return res.data
        } catch (err) {
          console.log(err)
          self.set("error", true)
          self.set("loading", false)
          return _throw(err)
        }
    },
    checkIfStillAuthorized(apiResponse) { 
      if (apiResponse.response.data.message === 'Unauthorized') {
        self.resetStore()
        Notify.addNotification({
          title: "No Access!",
          message: "You have been signed out because you don't have the necessary permissions. Please contact your administrator.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        return false
      } else {
        return true
      }
    },
    
    async logout() {
      getRoot(self).reset()
    },
    resetStore() {
      getRoot(self).reset()
    },
    set(key, value) {
      self[key] = value
    }
  }))


export default AuthStore
