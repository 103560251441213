import { types } from "mobx-state-tree"
import axios from 'axios'
import { _throw } from "../../utils/error-service"
import { API } from "../../constants/system"


const Asset = types
  .model('Asset', {
    id: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    fileSize: types.maybeNull(types.number),
    fileType: types.maybeNull(types.string),
    originUrl: types.maybeNull(types.string),
    mimetype: types.maybeNull(types.string),
    uploadedType: types.maybeNull(types.string),
    poster: types.maybeNull(types.string),
    localUrl: types.maybeNull(types.string),

    // uploading
    loading: false,
    error: types.maybeNull(types.string),
    progress: 0
  })
  .views(self => ({
    get viewableUrl() {
      return self.originUrl
    },
    get src() {
      if (!self.url) return self.localUrl
      else return self.url
    },
  }))
  .actions(self => ({
    async uploadAsset(file, fileType, token) {
      try {
        const res = await self.uploadAction(file, fileType, token)
        if (res.error) throw res
        
        return res
      } catch (err) {
        self.set('progress', 0)
        return {
          error: true,
          ...err
        }
      }
    },
    async upload(token, file, preview, type='CATEGORY_ICON') {
      try {
        self.set('loading', true)
        
        const data = new FormData()

        data.append('fileType', type)
        data.append('file', file)
        const res = await self.uploadAction(data, token, type)

        self.set('localUrl', preview) 
        self.set('originUrl', res.url)
        self.set('id', res.id)
        self.set('loading', false)

        return res

      } catch (err) {
        console.log(err)
        self.set('loading', false)
        return _throw(err)
      }
    },
    async uploadAction(data, authToken, type) {
      const config = {
        onUploadProgress: progressEvent => {
          const progress = progressEvent.loaded/progressEvent.total
          if (Math.ceil(progress*100) > 10)
            self.set('progress', Math.ceil(progress*100))
          },
        headers: {
          'content-type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authToken
        }
      }
      const res = await axios.post(`${API}/assets?fileType=${type}`, data, config)  
      return res.data
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default Asset