import { types } from 'mobx-state-tree'
import User from '../models/User'


const AccountStore = types
  .model('accountStore', {
    user: types.maybeNull(
      types.reference(User)
    ),
  })
  .views(self => ({
    get authenticated() {
      return false
    },
    get cmsUsername() {
      return self.user.username
    },
    get cmsName() {
      return self.user.name
    },
    get userRole() {
      if (self.user.role !== null) {
        return self.user.role.toLowerCase()
      } else  {
      return ''
      }
    }
  }))
  .actions(self => ({
    
    set(key, value) {
      self[key] = value
    }
  }))

export default AccountStore
