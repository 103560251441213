import { getRoot, types } from "mobx-state-tree"
import Location from "../models/Location"
import Asset from '../models/Asset'
import { api_delete_asset } from "../../queries/asset"
import { ASSET_TYPE } from "../../constants/types"
import { 
  api_post_gripe, 
  api_update_gripe, 
  api_report_gripe, 
  api_flag_gripe, 
  api_feature_gripe, 
  api_trending_gripe, 
  api_unflag_gripe,
  api_delete_gripe,
} from "../../queries/gripes"
import { api_update_comment } from "../../queries/comments"
import { toJS } from "mobx"
import { Store as Notify } from 'react-notifications-component';

const AddUpdateGripeStore = types
  .model('AddUpdateGripeStore', {
    title: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    location: types.optional(
      Location, {}
    ),
    category: types.optional(types.string, ''),
    tags: types.optional(types.string, ''),
    assets: types.optional(types.array(Asset), []),
    anonymous: types.maybeNull(types.boolean),
    
    id: types.optional(types.string, ''),
    editTitle: types.optional(types.string, ''),
    editText: types.optional(types.string, ''),
    editLocation: types.optional(
      Location, {}
    ),
    editCategory: types.optional(types.string, ''),
    editTags: types.optional(types.string, ''),
    editAssets: types.optional(types.array(Asset), []),
    editAnonymous: types.maybeNull(types.boolean),

    textError: types.maybeNull(types.string),
    titleError: types.maybeNull(types.string),
    locationError: types.maybeNull(types.string),
    categoryError: types.maybeNull(types.string),
    hashtagError: types.maybeNull(types.string),
    assetError: types.maybeNull(types.string),

    loading: false,
    loadingAsset: false,
    progress: 0,
  })
  .views(self => ({
    get formattedTags() {
      if (self.tags.length === 0) return []
      return self.tags?.split(',').map(t => t.trim())
    },
    get editFormattedTags() {
      if (self.editTags.length === 0) return []
      return self.editTags?.split(',').map(t => t.trim())
    },
    get accountStore() {
      return getRoot(self).accountStore
    },
    get authStore() {
      return getRoot(self).authStore
    },
    get gripeStore() {
      return getRoot(self).gripeStore
    },
    get gripesFactory() {
      return getRoot(self).gripesFactory
    },
    get token() {
      return getRoot(self).authStore.token
    },
  }))
  .actions(self => ({
    async submitGripe() {
      try {
        self.set('loading', true)
        self.clearErrors()

        const data = {
          title: self.title,
          text: self.text,
          anonymous: self.anonymous,
          categoryId: parseInt(self.category),
          assetsId: self.assets?.map(a => a.id),
          tags: self.formattedTags
        }

        if (self.location?.exactAddress !== null) 
          data.location = {
            exactAddress: self.location?.exactAddress,
            lng: self.location?.lng,
            lat: self.location?.lat
          } 

        const res = await api_post_gripe({data, token: self.accountStore.token})

        if (res.error) throw res

        self.gripesFactory.addUpdateGripe(res.data)
        self.clearValues()

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async updateGripe(title, text, categoryId, tags, gripeId, location, featured, trending) {

      let parsedTagsForApi = tags.map(tag => tag.id);

      try {
        self.set('loading', true)
        self.clearErrors()
       
        const data = {
          title: title,
          text: text,
          categoryId: categoryId,
          tags: parsedTagsForApi,
          featured: featured,
          trending: trending,
        }
        
        const res = await api_update_gripe(data, self.token, gripeId)
        if (res.error) throw res
        
        self.gripesFactory.addUpdateGripe(res.data)
        //self.clearValues()

        Notify.addNotification({
          title: "Gripe updated!",
          message: "You have successfully updated the gripe.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)

        Notify.addNotification({
          title: "Update failed!",
          message: "There was an error updating the gripe.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.handleErrors(err)

        return err
      }
    },
    async resetReportedGripeCounter(gripeId) {

      try {
        self.set('loading', true)
        self.clearErrors()
       
        const data = {
          reported: 0,
        }
        
        const res = await api_update_gripe(data, self.token, gripeId)
        if (res.error) throw res
        
        self.gripesFactory.addUpdateGripe(res.data)

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)

        self.handleErrors(err)

        return err
      }
    },
    async flagGripeTest(status, gripeId ) {

      try {
        self.set('loading', true)
        self.clearErrors()
       
        const data = {
          status: status,
        }
          
        const res = await api_update_gripe(data, self.token, gripeId)
        if (res.error) throw res
        
        self.gripesFactory.addUpdateGripe(res.data)
        //self.clearValues()

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async deleteGripe(gripeId) {
      try {
        self.set('loading', true)
        self.clearErrors()
       
        const data = {
          status: 'DELETED',
        }
          
        const res = await api_update_gripe(data, self.token, gripeId)
        if (res.error) throw res
        
        self.gripesFactory.addUpdateGripe(res.data)

        Notify.addNotification({
          title: "Gripe deleted!",
          message: "You have successfully deleted the gripe.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        self.set('loading', false)
        return res.data
      } catch (err) {
        Notify.addNotification({
          title: "Gripe delete failed!",
          message: "There was an error deleting the gripe.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async deleteGripePaginate(gripeId, page, limit) {
      try {
        self.set('loading', true)
        self.clearErrors()
       
        const res = await api_delete_gripe(gripeId, self.token)
        if (res.error) throw res
        self.gripeStore.getGripesPaginate(page, limit)

        Notify.addNotification({
          title: "Gripe deleted!",
          message: "You have successfully deleted the gripe.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        
        self.set('loading', false)
        return res.data
      } catch (err) {
        Notify.addNotification({
          title: "Gripe delete failed!",
          message: "There was an error deleting the gripe.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async deleteComment(commentId) {
      try {
        self.set('loading', true)
        self.clearErrors()
       
        const data = {
          status: 'DELETED',
        }
          
        const res = await  api_update_comment(data, self.token, commentId)
        if (res.error) throw res

        self.gripeStore.getCommentsPaginate(self.gripeStore.selectedGripe.id)

        Notify.addNotification({
          title: "Comment deleted!",
          message: "You have successfully deleted the comment.",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        
        self.set('loading', false)
        return res.data
      } catch (err) {
        Notify.addNotification({
          title: "Comment delete failed!",
          message: "There was an error deleting the comment.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async flagGripe(gripeId) {
      try {
        self.set('loading', true)
       
        const data = {
          status: 'FLAGGED',
        }
          
        const res = await api_flag_gripe(data, gripeId, self.token)
        if (res.error) throw res

        self.resetReportedGripeCounter(gripeId)
        self.gripesFactory.addUpdateGripe(res.data)

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async setfGripe(gripeId) {
      try {
        self.set('loading', true)
       
        const data = {
          status: 'FLAGGED',
        }
          
        const res = await api_flag_gripe(data, gripeId, self.token)
        if (res.error) throw res
        
        self.gripesFactory.addUpdateGripe(res.data)

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async unflagGripe(gripeId) {
      try {
        self.set('loading', true)
       
        const data = {
          status: 'ACTIVE',
        }
          
        const res = await api_unflag_gripe(data, gripeId, self.token)
        if (res.error) throw res
        
        self.gripesFactory.addUpdateGripe(res.data)

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async featuredGripe(gripeId, status) {
      try {
        self.set('loading', true)
       
        const data = {
          featured: status,
        }
          
        const res = await api_feature_gripe(data, self.token, gripeId)
        if (res.error) throw res
        
        if (res.data.new !== null) {
          self.gripesFactory.addUpdateGripe(res.data.new)
        }
        if (res.data.old !== null) {
          self.gripesFactory.addUpdateGripe(res.data.old)

        }
        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async trendingGripe(gripeId, status) {
      try {
        self.set('loading', true)
       
        const data = {
          trending: status,
        }
          
        const res = await api_trending_gripe(data, self.token, gripeId)
        if (res.error) throw res
        
        self.gripesFactory.addUpdateGripe(res.data)

        self.set('loading', false)
        return res.data
      } catch (err) {
        self.set('loading', false)
        self.handleErrors(err)
        return err
      }
    },
    async reportGripe(id, reason) {
      try {
        const res = await api_report_gripe({id, reason: reason, token: self.accountStore.token})
        if (res.error) throw res

        return res
      } catch (err) {
        //_showToast(err.data?.details ? 
          //err.data.details : "Oops, something went wrong!")
        return err
      }
    },
    async uploadFile(file, edit = false) {
      try {
        self.set('loadingAsset', true)
        self.set('assetError', null)

        const asset = Asset.create({})

        let fileType = ""
        if (file.file.type.includes("image"))
          fileType = ASSET_TYPE.GRIPE_IMG
        else if (file.file.type.includes("video"))
          fileType = ASSET_TYPE.GRIPE_VIDEO
        else if (file.file.type.includes("audio"))
          fileType = ASSET_TYPE.GRIPE_AUDIO

        const res = await asset.uploadAsset(file, fileType,
          self.accountStore.token)
        if (res.error) throw res

        if (edit)
          self.set('editAssets', self.editAssets.concat([res]))
        else
          self.set('assets', self.assets.concat([res]))
        self.set('loadingAsset', false)

        return res
      } catch (err) {
        if (err.data?.details) self.set('assetError', err.data.details)
        self.set('progress', 0)
        self.set('loadingAsset', false)
        return err
      }
    }, 
    async deleteAsset(id, edit = false) {
      try {
        self.set('loadingAsset', true)
        self.set('assetError', null)

        const res = await api_delete_asset({id, token: self.accountStore.token})
        if (res.error) throw res
        
        if (edit) {
          let assets = self.editAssets.filter(a => a.id !== id)
          self.set('editAssets', assets)
        } else {
          let assets = self.assets.filter(a => a.id !== id)
          self.set('assets', assets)
        }
        self.set('loadingAsset', false)
        return res
      } catch (err) {
        self.set('loadingAsset', false)
        if (err.data?.details) self.set('assetError', err.data.details)
        return err
      }
    },
    clearValues() {   
      // self.set('title', ''),
      // self.set('text', ''),
      // self.set('location', {})
      // self.set('category', ''),
      // self.set('tags', ''),
      // self.set('assets', [])
      // self.set('editTitle', ''),
      // self.set('editText', ''),
      // self.set('editLocation', {})
      // self.set('editCategory', ''),
      // self.set('editTags', ''),
      // self.set('editAssets', [])
    },
    populateEditFields(gripe) {
      self.set('editTitle', gripe.title)
      self.set('editText', gripe.text)
      if (gripe?.location) 
        self.set('editLocation', toJS(gripe.location))
      self.set('editCategory', gripe.category.id.toString())
      // self.set('editTags', gripe.tags.length !== 0 ? 
      //   gripe.tags.map(tag => tag.tag).slice().join(', ') : ''),
      // self.set('editAssets', gripe.assets.length !== 0 ? 
      //   gripe.assets.map(asset => toJS(asset)) : []),
      self.set('editAnonymous', gripe.anonymous)
      self.set('id', gripe.id)
    },
    clearErrors() {
      // self.set('textError', null),
      // self.set('titleError', null),
      // self.set('locationError', null),
      // self.set('categoryError', null),
      // self.set('hashtagError', null)
    },
    handleErrors(err) {
      console.log(err)
      self.authStore.checkIfStillAuthorized(err)
    },
    init() {
      self.set('anonymous', self.accountStore.user?.anonymous ? true : false);
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default AddUpdateGripeStore