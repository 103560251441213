import { types, destroy, getRoot } from "mobx-state-tree"
import Location from "./Location"
import Asset from "./Asset"
import User from "./User"
import Category from "./Category"
import Hashtag from "./Hashtag"
import Comment from "./Comment"
import Reaction from "./Reaction"
import { DateTime } from 'luxon'
import { REACTION_TYPES } from "../../constants/types"
import filter from 'lodash/filter'
import find from 'lodash/find'
import differenceBy from 'lodash/differenceBy'
import intersectionBy from 'lodash/intersectionBy'
import map from 'lodash/map'
import { format, parse } from "date-fns"

const Gripe = types
  .model("Gripe", {
    id: types.identifier,
    title: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    anonymous: types.maybeNull(types.boolean),
    featured: types.maybeNull(types.boolean),
    trending: types.maybeNull(types.boolean),
    reported: types.maybeNull(types.number),
    location: types.maybeNull(
      Location
    ),
    tags: types.optional(
      types.array(Hashtag), []
    ),
    assets: types.optional(
      types.array(Asset), []
    ),
    user: types.maybeNull(types.reference(User)),
    comments: types.optional(
      types.array(Comment), []),
    reactions: types.optional(
      types.array(Reaction), [
        {
          counter: 0,
          liked: false,
          type: REACTION_TYPES.TRIDENT
        },
        {
          counter: 0,
          liked: false,
          type: REACTION_TYPES.ENRAGED_FACE
        },
        {
          counter: 0,
          liked: false,
          type: REACTION_TYPES.ANGER
        }
      ]),
    category: types.maybeNull(types.reference(Category)),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  })
  .views(self => ({
    get postTime() {
      let now = DateTime.now()
      let created = DateTime.fromISO(self.createdAt)
      let difference = created.until(now)
        .toDuration(['months', 'weeks', 'days', 'hours', 'minutes']).toObject()

      if (difference.months !== 0)
        return Math.round(difference.months) + " month" + 
            (difference.months > 1 ? "s" : "") + " ago"
      else if (difference.weeks !== 0)
        return Math.round(difference.weeks) + " week" + 
            (difference.weeks > 1 ? "s" : "") + " ago"
      else if (difference.days !== 0)
        return Math.round(difference.days) + " day" + 
            (difference.days > 1 ? "s" : "") + " ago"
      else if (difference.hours !== 0)
        return Math.round(difference.hours) + " hour" + 
            (difference.hours > 1 ? "s" : "") + " ago"
      else
        return Math.round(difference.minutes) + " minute" + 
            (difference.minutes !== 1 ? "s" : "") + " ago"
    },
    get usersFactory() {
      return getRoot(self).usersFactory
    },
    get gripeCreationTime() {
      let created = parse(self.createdAt, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date())
      return format(created, "dd. MMM yyyy ' at ' HH:mm")
    },
    get hashtagsInput() {
      let newArray = []
      self.tags.map((tag) =>{
        let b =  {
          id: tag.tag,
          text: tag.tag
        }
        newArray.push(b)
      })

      return newArray
    }
  }))
  .actions(self => ({
    addUpdateComments(cmnts) {
      const comments = self.parseComments(cmnts)
      const add = differenceBy(comments, self.comments, 'id')
      const update = intersectionBy(self.comments, comments, 'id')
      map(update, c => c.update(
        filter(comments, cc => cc.id === c.id)[0]
      ))
      self.comments.push(...add)
      return map(comments, c => c.id)
    },
    addUpdateComment(comment) {
      const ids = self.addUpdateComments([comment])
      return ids[0]
    },
    getComment(id) {
      return find(self.comments, c => c.id === id)
    },
    deleteComment(id) {
      let comment = self.getComment(id)
      destroy(comment)
    },
    parseComments(cmnts) {
      return map(cmnts, c => ({
          ...c,
          user: c.user?.id ? self.usersFactory.addUpdateUser(c.user, "hello") : null
      }))
    },
    reactionId(name) {
      return self.reactions?.findIndex(r => r.name === name)
    },
    updateReactions(reactions) {
      let arr = reactions.map(function(val) {
        return {...val, counter: parseInt(val.counter)}
      })
      self.set('reactions', arr)
    },
    removeReactions() {
      self.set('reactions', [])
    },
    update(gr) {
      self.set('title', gr.title)
      self.set('text', gr.text)
      self.set('status', gr.status)
      self.set('category', gr.category)
      self.set('createdAt', gr.createdAt)
      self.set('anonymous', gr.anonymous)
      self.set('featured', gr.featured)
      self.set('location', gr.location)
      self.set('reported', gr.reported)
      if (gr.reactions) 
        self.set('reactions', gr.reactions)
      if (gr.comments)
        self.set('comments', gr.comments)
      self.set('tags', gr.tags)
      self.set('assets', gr.assets)
      self.set('user', gr.user)
    },
    reactionId(type) {
      return self.reactions?.findIndex(r => r.type === type)
    },
    set(key, value) {
      self[key] = value
    }
  }))


export default Gripe