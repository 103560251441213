import axios from 'axios'
import { API, BASIC_AUTH } from '../constants/system'
import { _throw } from '../utils/error-service'


export const api_get_gripes = async (token) => {
  try {
    const res = await axios.get(`${API}/cms/gripes`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_gripes_paginate = async (token, page, row) => {
  try {
    const res = await axios.get(`${API}/cms/gripes?page=${page}&items=${row}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_gripes_paginate_reported = async (token, page, row) => {
  try {
    const res = await axios.get(`${API}/cms/gripes/all/reported?page=${page}&items=${row}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_gripe = async (data) => {
  try {
    const res = await axios.get(`${API}/gripes/${data.gripe}`, {
      headers: {
        'Authorization': data.token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_gripe_by_id = async (id, token) => {
  try {
    const res = await axios.get(`${API}/cms/gripes/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_post_gripe = async (data) => {
  try {
    const res = await axios.post(`${API}/gripes`, data.data, {
      headers: {
        'Authorization': data.token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_gripe = async (data, token, gripeId) => {
  try {
    const res = await axios.put(`${API}/cms/gripes/${gripeId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_feature_gripe = async (data, token, gripeId) => {
  try {
    const res = await axios.put(`${API}/cms/gripes/${gripeId}/modify-featured`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_trending_gripe = async (data, token, gripeId) => {
  try {
    const res = await axios.put(`${API}/cms/gripes/${gripeId}/modify-trending`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}


export const api_report_gripe = async (data) => {
  try {
    const res = await axios.post(`${API}/gripes/${data.id}/report`, data.reason, {
      headers: {
        'Authorization': data.token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_flag_gripe = async (data, id, token) => {
  try {
    const res = await axios.post(`${API}/cms/gripes/${id}/flag`, { }, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_unflag_gripe = async (data, id, token) => {
  try {
    const res = await axios.post(`${API}/cms/gripes/${id}/unflag`, { }, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delete_gripe = async (id, token) => {
  try {
    const res = await axios.delete(`${API}/gripes/${id}/archive`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}


export const api_get_gripes_by_username = async (data) => {
  try {
    const res = await axios.get(`${API}/gripes/user/${data.username}`, {
      headers: {
        'Authorization': data.token ? data.token : BASIC_AUTH
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}