import axios from 'axios'
import { API } from '../constants/system'
import { _throw } from '../utils/error-service'

export const api_invite_new_team_user = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/auth/invite`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_team_user = async (data, token, username) => {
  try {
    const res = await axios.put(`${API}/cms/users/${username}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

