import { types, getRoot } from "mobx-state-tree"
import { DateTime } from 'luxon'
import User from './User'

const Comment = types
  .model("Comment", {
    id: types.maybeNull(types.number),
    text: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    anonymous: types.maybeNull(types.boolean),
    user: types.maybeNull(types.reference(User)),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    myComment: types.maybeNull(types.boolean)
  })
  .views(self => ({
    get postTime() {
      let now = DateTime.now()
      let created = DateTime.fromISO(self.createdAt)
      let difference = created.until(now)
        .toDuration(['months', 'weeks', 'days', 'hours', 'minutes']).toObject()

      if (difference.months !== 0)
        return Math.round(difference.months) + " month" + 
            (difference.months > 1 ? "s" : "") + " ago"
      else if (difference.weeks !== 0)
        return Math.round(difference.weeks) + " week" + 
            (difference.weeks > 1 ? "s" : "") + " ago"
      else if (difference.days !== 0)
        return Math.round(difference.days) + " day" + 
            (difference.days > 1 ? "s" : "") + " ago"
      else if (difference.hours !== 0)
        return Math.round(difference.hours) + " hour" + 
            (difference.hours > 1 ? "s" : "") + " ago"
      else
        return Math.round(difference.minutes) + " minute" + 
            (difference.minutes !== 1 ? "s" : "") + " ago"
    },
    get usersFactory() {
      return getRoot(self).usersFactory
    }
  }))
  .actions(self => ({
    update(comment) {
      self.set('text', comment.text)
      self.set('anonymous', comment.anonymous)
      self.set('createdAt', comment.createdAt)
      self.set('updatedAt', comment.updatedAt)
      self.set('myComment', comment.myComment)
      self.set('status', comment.status)
      self.set('user', comment.user)
    },
    checkIfUserExists(comment) {
      console.log()
      //self.set('user', self.usersFactory.addUpdateUser(comment.user, "this problem?"))
    },

    set(key, value) {
      self[key] = value
    }
  }))


export default Comment