
import { types } from 'mobx-state-tree'
import AccountStore from '../stores/accountStore'
import AuthStore from '../stores/authStore'
import UsersFactory from '../factories/usersFactory'
import GripesFactory from '../factories/gripesFactory'
import CategoriesFactory from '../factories/categoriesFactory'
import GripeStore from '../stores/gripeStore'
import AddUpdateGripeStore from '../stores/addUpdateGripeStore'
import CategoriesStore from '../stores/categoriesStore'
import UsersStore from '../stores/usersStore'
import TeamStore from '../stores/teamStore'


const Store = types
  .model({
    accountStore: types.optional(AccountStore, {}),
    authStore: types.optional(AuthStore, {}),
    gripeStore: types.optional(GripeStore, {}),
    addUpdateGripeStore: types.optional(AddUpdateGripeStore, {}),
    categoriesStore: types.optional(CategoriesStore, {}),
    usersStore: types.optional(UsersStore, {}),
    usersFactory: types.optional(UsersFactory, {}),
    gripesFactory: types.optional(GripesFactory, {}),
    categoriesFactory: types.optional(CategoriesFactory, {}),
    teamStore: types.optional(TeamStore, {}),

    hydrated: false
  })
  .views(self => ({

  }))
  .actions(self => ({
    reset() {
      self.set('authStore', {})
      self.set('accountStore', {})
      
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default Store
